<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="progress-root"
    data-key-anchor="Ход строительства"
  >
    <div class="container">
      <div class="titleBlock">
        <div
          class="h1"
          v-html="values.title"
        ></div>
        <div
          v-if="values.online_cams.length"
          class="cams"
        >
          <a
            v-for="camera in values.online_cams"
            :key="camera.link"
            class="cam-item"
            target="_blank"
            :href="camera.link"
          >
            <img
              src="~/assets/img/svg/project/camera.svg"
              alt="camera"
            >
            <span>Онлайн камера</span>
          </a>
        </div>
      </div>
      <div class="items-block">
        <swiper
          :space-between="8"
          :slides-per-view="1.3"
          :centered-slides="false"
          :effect="'fade'"
          :breakpoints="{
            [Breakpoint.lg]: {
              slidesPerView: 4,
              spaceBetween: 20
            }
          }"
          @swiper="progressBlockSwiper.setSliderRef"
        >
          <swiper-slide
            v-for="(item, idx) in props.extras.periods"
            :key="`item-${idx}`"
          >
            <ConstructionItem
              :period="item"
              :index="idx"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';

import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import {Breakpoint} from "~/enums/Breakpoint";

export type ProgressPeriod = {
	id: number,
	images: { imageId: number }[],
	object: string,
	preview: number,
	title: string
}

type ConstructionProgressBlockValues = {
	title: string,
	online_cams: { link: string, title: string }[],
}

type ConstructionProgressBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
	values: ConstructionProgressBlockValues;
	cfgs: SectionTypes;
	extras: {
		periods: ProgressPeriod[]
	}
}
const { $customSectionClasses } = useNuxtApp();

const props = defineProps<ConstructionProgressBlockType>();

const progressBlockSliderRef = ref<SwiperClass | null>(null);
const progressBlockSwiper = setCustomSwiper(progressBlockSliderRef);
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.progress-root.custom-section {
	background-color: var(--primary-10);
}

.titleBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 70px;

	& > h1 {
		margin-bottom: 0;
	}
}

.cams {
	display: flex;
	gap: 10px;

	& > .cam-item {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		padding: 8px 14px;
		border-radius: 48px;
		border: 1px solid var(--primary-20);
		background: #FFF;
		box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2px 4px 0 rgb(0 0 0 / 4%), 0 7px 7px 0 rgb(0 0 0 / 4%), 0 16px 9px 0 rgb(0 0 0 / 3%), 0 28px 11px 0 rgb(0 0 0 / 1%), 0 44px 12px 0 rgb(0 0 0 / 0%);
		cursor: pointer;
		transition: opacity .2s linear;

		&:hover {
			opacity: .8;
		}

		& > span {
			color: var(--primary);
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 16px;
			letter-spacing: -0.18px;
		}
	}
}

.items-block {
	& > .swiper {
		padding-bottom: 30px;
	}
}

@include media('sm') {
	.titleBlock {
		flex-direction: column;
		align-items: center;
		gap: 25px;
		margin-bottom: 25px;
	}
}
</style>
